export function supportChat () {
	jQuery('#supportChat').css({
		'left': 'auto',
		'right': '10%',
	});
	var chatBtn = null;

	// init onclick both for right-bottom button and live-chat link in sidebar me
	jQuery('#supportChat, .live-help-menu-item a').click(function () {
		var iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
		if (iOS) {
			var url = 'https://simply.ladesk.com/scripts/generateWidget.php?v=4.31.4.4&t=1463557486&cwid=d93152a2&cwt=chat_mobile&pt=ladesk';
			window.open(url);
		} else if (typeof(LiveAgent) == 'undefined') {
			var scriptElement = document.createElement('script');
			scriptElement.id = 'la_x2s6df8d';
			scriptElement.src = 'https://simply.ladesk.com/scripts/track.js';
			scriptElement.onload = function () {
				initChatBtn();
			};
			document.body.appendChild(scriptElement);
		} else if (!chatBtn) {
			initChatBtn();
		} else {
			openChat();
		}
	});
	jQuery(document).on("click", "#trigger_support_chat, .trigger_support_chat", function(e){
		e.preventDefault();
		jQuery("#supportChat").trigger("click");
	});

	function initChatBtn() {
		var userEmail = jQuery(this).data('email');
		var userFirstName = jQuery(this).data('name');

		let keyBtn = 'm9lhxzn7';

		LiveAgent.setUserDetails(userEmail , userFirstName);
		chatBtn = LiveAgent.createButton(keyBtn, document.getElementById('originalButtonContainer'));
		// wait to init button
		chatBtn.old_onOnline = chatBtn.onOnline;
		chatBtn.old_onOffline = chatBtn.onOffline;
		chatBtn.onOnline = function () {
			chatBtn.old_onOnline();
			openChat();
		};
		chatBtn.onOffline = function () {
			chatBtn.old_onOffline();
			openChat();
		};
	}

	function openChat() {
		setTimeout(function () {
			chatBtn.onClick();
		});
	}

	if (window.location.search.indexOf('init-live-help') > -1) {
		jQuery('#supportChat, .live-help-menu-item a').click();
	}
}
	
