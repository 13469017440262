export class FeaturesAccordion {
    constructor(options) {
        this.options = $.extend({
            accordion: $('.js-features-accordion'),
            accordionItemsTitle: $('.js-features-accordion-item-title'),
        }, options);

        this._init();
    }

    _init() {
        this.options.accordionItemsTitle.on('click', (e) => {
            const accordionTitle = $(e.currentTarget);
            const accordion = accordionTitle.closest('.js-features-accordion');
            const accordionItem = accordionTitle.closest('.js-features-accordion-item');
            const accordionContent = accordionItem.find('.js-features-accordion-item-content');
            const accordionImageId = accordionItem.attr('data-image-id');
            const accordionImageWrapper = accordion.find('.js-features-accordion-image');
            const accordionImage = accordionImageWrapper.find(`img[data-image-id="${accordionImageId}"]`);

            if (!accordionItem.hasClass('opened')) {
                accordionImageWrapper.addClass('fade');

                setTimeout(() => {
                    accordionImage.css('display', 'block');
                    accordionImageWrapper.removeClass('fade');
                    $('.js-features-accordion-image img', accordion).not(accordionImage).css('display', 'none');
                }, 300);
            }

            accordionItem.addClass('opened');
            accordionContent.css('max-height', `${accordionContent[0].scrollHeight}px`);

            $('.js-features-accordion-item-content', accordion).not(accordionContent).css('max-height', 0);
            $('.js-features-accordion-item', accordion).not(accordionItem).removeClass('opened');
        });

        // Open first element on load
        this.options.accordion.find('.js-features-accordion-item:first-child .js-features-accordion-item-title').click();

    }
}


