require('../components/ui-components/modal-bootstrap-v3.4.1');

const lazyImages = require('../components/ui-components/LazyLoad.js').lazyImages;
const videoPopup = require('../components/ui-components/VideoPopup').videoPopup;

const {onModeEnter, onModeLeave, getMode} = require('../media.js');
const StickyHeader = require('./StickyHeader').StickyHeader;
const Icons = require('../Icons.js').Icons;
const effects = require('../effects.js');

const debug = require('../debug.js');
const support = require('./support.js');
const $ = require("jquery");

const FeaturesAccordion = require('./FeaturesAccordion.js').FeaturesAccordion;

// we do not need it for a while
//global.Registration = require('../registration/registration').Registration;

global.Login = require('../login/login').Login;
global.Feedback = require('../feedback/feedback').Feedback;
global._t = _t;

//console.log('console.log in app.js')

new StickyHeader();
new Icons();

new FeaturesAccordion();

// Handle lazy loading images
lazyImages();
// Handle lazy loading youtube video in popups
videoPopup();

support.supportChat();

effects.addRippleToButton($('.btn, li.to-previous-step'));

debug.showScreenSize();

var menuWasOpen = false;

$('.header--togg-menu').click(function () {
    menuWasOpen = !menuWasOpen;
    closeMenu();
});

$('.morph-shape, .header--mob-togg-menu').click(function () {
    menuWasOpen = false;
    closeMenu();
});

function closeMenu() {
    if (menuWasOpen) {
        $('body').addClass('menu--opened');
        //$('#header').addClass('header--stickly');
        $('.header--togg-menu, .header .morph-shape').addClass('open');

        // AnimateSvgOpenMobile(function () {
            $('.header--main-nav').addClass('open');
        // });

    } else {
        $('body').removeClass('menu--opened');
        //$('#header').removeClass('header--stickly');
        $('.header--main-nav').removeClass('open');
        $('.header--main-nav .menu--link-has-dropdown .submenu').removeClass('opened');
        // AnimateSvgCloseMobile(function () {
            $('.header--togg-menu, .morph-shape').removeClass('open');
        // });
    }
}


// var g_heightHeader, g_windowHeight, g_windowHeightThird, g_windowWidth;
// g_heightHeader = $('.header .header--settings-block .header--settings-container').height();
// g_windowHeight = $(window).height() - g_heightHeader;
// g_windowHeightThird = (g_windowHeight / 3);
// g_windowWidth = $(window).width();
//
$(window).resize(function () {
    // g_heightHeader = $('.header .header--settings-block .header--settings-container').height();
    // g_windowHeight = $(window).height() - g_heightHeader;
    // g_windowHeightThird = (g_windowHeight / 3);
    // g_windowWidth = $(window).width();
    //setHeightForCategoryItem();

    if ($(window).width() > 768) {
        menuWasOpen = false;
        closeMenu();
    }
});

// function reviewAccordion() {
//     $('.reviews--slider .read-more').click(function () {
//         console.log('click');
//         $(this).toggleClass('open');
//         var txtBlock = $(this).closest('.short-review').toggleClass('full-review');
//         $('.short-review').not($(this).closest('.short-review')).removeClass('full-review');
//
//
//         if ( !$(txtBlock).hasClass("full-review") ) {
//             txtBlock.animate({'max-height': 76});
//         }
//         else {
//             //console.log('txtBlock.animate({\'max-height\': txtBlock[0].scrollHeight + "px"}')
//             txtBlock.animate({'max-height': txtBlock[0].scrollHeight + "px"}, {
//                 step: function () {
//                     $('.reviews--slider').slick('resize');
//                     $('.reviews--slider').slick("setOption", null, null, true);
//                 }
//             });
//         }
//
//
//         $('.reviews--slider .short-review').not($(this).closest('.short-review')).css('max-height', 76);
//         $('.reviews--slider .read-more').not($(this)).removeClass('open');
//
//         resizeReviewsCrousel();
//         setTimeout(function () {
//             resizeReviewsCrousel();
//         }, 100)
//
//        function resizeReviewsCrousel(){
//            $('.reviews--slider').slick('resize');
//            $('.reviews--slider').find(".slick-slide").height("auto");
//            $('.reviews--slider').find(".slick-list.draggable").height("auto");
//        }
//
//         // Close current review description on next slider move
//         $('.reviews--slider .slick-dots li:not(.slick-active) button').click(function () {
//             //console.log('Close current review description on next slider move')
//             txtBlock.animate({'max-height': 76});
//             $('.reviews--slider .read-more').removeClass('open');
//         });
//     });
// }
//
// reviewAccordion();

function toggleReviewText() {
    const reviewTextMinHeight = 105;
    let reviewBody = $(this).closest('.js-review-body');
    let reviewTextBlock = reviewBody.find('.js-review-body-text');
    let reviewShowLessBtn = reviewBody.find('.js-review-show-less');
    let reviewReadMoreBtn = reviewBody.find('.js-review-read-more');

    reviewReadMoreBtn.toggle();
    reviewShowLessBtn.toggle();

    if (reviewTextBlock.height() == 105) {
        reviewTextBlock.css('max-height', `${reviewTextBlock[0].scrollHeight}px`);
    } else {
        reviewTextBlock.css('max-height', `${reviewTextMinHeight}px`);
    }

    $('.js-review-body-text').not(reviewTextBlock).css('max-height', reviewTextMinHeight);
    $('.js-review-read-more').not(reviewReadMoreBtn).show();
    $('.js-review-show-less').not(reviewShowLessBtn).hide();
}

$('.js-review-read-more, .js-review-show-less').on('click', function() {
    toggleReviewText.apply($(this));
});

function openSubMenuOnTuch() {
    $('.header--navigation-menu-container').on('click', '.header--main-nav-mobile .menu--link-has-dropdown', function () {
        var get_data = $(this).data("submenu");
        var sub_menu = $(this).closest('.header--main-nav-mobile .main-nav--menu').find('#' + get_data);
        $(sub_menu).addClass('opened');
    });
}

openSubMenuOnTuch();

function goToPrevMenuOnTuch() {
    $('.header--navigation-menu-container ').on('click', '.header--main-nav-mobile .submenu .to-previous-step', function (e) {
        $('.submenu').removeClass('opened');
        e.stopPropagation();
    })
}

goToPrevMenuOnTuch();

function smoothScrollAnchorLinks() {
    $('.anchor-link').click(function (e) {
        e.preventDefault();
        $('.anchor-link').removeClass('active');
        //$(this).addClass('active');
        var target = $($(this).attr('href'));
        var offset_value = $(this).data('offset');

        if (getMode() != 'desktop') {
            offset_value = $(this).data('offset') - $(this).height();
        }
        if (getMode() == 'mob') {
            offset_value = 0;
        }

        $(window).resize(function () {
            if (getMode() != 'desktop') {
                offset_value = $(this).data('offset') - $(this).height();
            }
            if (getMode() == 'mob') {
                offset_value = 0;
            }
        });

        if (offset_value > 0) {
           // console.log('has offset');
            if (target.length) {
                var scrollTo = target.offset().top - offset_value;
                $('body, html').stop().animate({scrollTop: scrollTo + 'px'}, 800);
            }
        } else {
            //console.log('no offset');
            if (target.length) {
                var scrollTo = target.offset().top;
                $('body, html').stop().animate({scrollTop: scrollTo + 'px'}, 800);
            }
        }

    });
}

smoothScrollAnchorLinks();

function scrollToBlock(cur_block, callback, additionalOffset) {
    if ($(cur_block).length == 0) {
        return;
    }

    var isIn = checkIsIN();

    var additionalOffsetValue = additionalOffset;

    if (callback) callback(isIn);

    function checkIsIN() {
            var hT = cur_block.offset().top,
                hH = cur_block.outerHeight(),
                wH = $(window).height(),
                wS;

            if (additionalOffsetValue) {
                wS = $(window).scrollTop() + additionalOffsetValue;
            } else {
                wS = $(window).scrollTop();
            }


                return wS > (hT + hH - wH) && (hT > wS) && (wS + wH > hT + hH);
    }

    document.addEventListener('scroll', function () {
        if (checkIsIN()) {
           // console.log('in view');
            cur_block.addClass('in');
            if (!isIn) {
                if (callback) callback(true);
                isIn = true;
            }

        } else {
           // console.log('not in view');
            cur_block.removeClass('in');
            if (isIn) {
                if (callback) callback(false);
                isIn = false;
            }
        }
    }, {passive: true});
}

scrollToBlock($('.faq--question-list .container'), function (isIn) {
    if (isIn) {
        window.dispatchEvent(new Event('resize'));
    }
});



// Accept payments management carousel
var $carousel = $('.ap-management__carousel');
var $line = $carousel.find('.ap-management__line');

if ($carousel.length > 0) {
    $line.each(function (index, el) {
        $(this).clone().addClass('cloned').insertAfter(this);
    });
}

function _t(key, options) {
    let debugLang = false;
    let str = key;
    if (!(typeof LANG != 'undefined') && LANG[key]) {
        str = LANG[key];
    } else if (debugLang) {
        console.warn('Lang key missing', key);
    }

    if (options !== undefined) {
        for (let k in options) {
            if (options.hasOwnProperty(k)) {
                let v = options[k];
                str = (str + '').replace('%' + k + '%', v);
            }
        }
    }

    return str;
}

const toggleShortMenuDropdown = () => {
    let menuWithDropdown = $('.has-dropdown');
    let dropdownEl = menuWithDropdown.children('.menu__dropdown');

    menuWithDropdown.on('mouseenter', function () {
        $(this).children('.menu__dropdown').addClass('opened');
    });

    dropdownEl.on('mouseleave', () => {
        dropdownEl.removeClass('opened');
    });

    $(document).on('click', () => {
        dropdownEl.hasClass('opened') ? dropdownEl.removeClass('opened') : '';
    });
};

toggleShortMenuDropdown();

if($('.faq-section .masonry-grid--item-toggler')){
    $('.faq-section .masonry-grid--item-toggler').on('click', function (){
        $(this).toggleClass('--active');
        $(this).closest('.masonry-grid--item').find('.masonry-grid--item-body').slideToggle();
    })
}


function setWithExpiry(key, value, ttl) {
    const now = new Date()
    const item = {
        value: value,
        expiry: now.getTime() + (ttl * 24 * 60 * 60 * 1000), // (ttl * 24 * 60 * 60 * 1000) - use for days, now in seconds (ttl - only in seconds)
    }
    localStorage.setItem(key, JSON.stringify(item))
}

function getWithExpiry(key) {
    const itemStr = localStorage.getItem(key)
    // if the item doesn't exist, return null
    if (!itemStr) {
        return null
    }
    const item = JSON.parse(itemStr)
    const now = new Date()
    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        localStorage.removeItem(key)
        return null
    }
    return item.value
}


if($('#cookies__close-btn')){
    $('#cookies__close-btn').on('click', function (){
        setWithExpiry('hideCookieCover', 1, 7);
        $('.cookies').addClass('cookies__footer-banner');
    })
}

if( getWithExpiry('hideCookieCover') != null ){
    $('.cookies').addClass('cookies__footer-banner');
} else{
    $('.cookies').removeClass('cookies__footer-banner');
}

console.log( 'hideCookieCover = ',  getWithExpiry('hideCookieCover'));
let cookieList = document.cookie;
let cookieListArr =  cookieList.split(';');
// console.log(cookieList)
// console.log( cookieList.split(';') )
// cookieListArr.map((item) => {
//     console.log(item)
// })

//hide cookie banner for Igor's pages & Michail's pages
if (window.location.href.indexOf("igor.dev18") > -1 || window.location.href.indexOf("memosh.dev18") > -1) {
    $('.cookies').fadeOut();
}
